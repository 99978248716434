import { render, staticRenderFns } from "./FutureGames.vue?vue&type=template&id=7387df46&scoped=true&"
import script from "./FutureGames.vue?vue&type=script&lang=js&"
export * from "./FutureGames.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7387df46",
  null
  
)

export default component.exports