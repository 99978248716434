<template>
<div class="container">
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
<!--  Header-->
  <div class="text-center mt-5">
    <h1>Book1</h1>
  </div>


  <!--  Booking items-->
  <div class="row ml-5 mr-5">
    <div v-if="!is_selected_game" class="col-lg-4 col-md-4 col-sm-6 col-10" v-for="game in games" :key="game.id">
      <card >
        <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
        <h4 class="card-title">{{game.name}}</h4>
        <p v-show="game.min_players_count != null">Players: &nbsp; &nbsp;{{game.min_players_count}}
          <span v-show="game.max_players_count != null"> - </span> {{game.max_players_count}}</p>
        <p v-show="game.duration_minutes != null">Duration: &nbsp; &nbsp;{{game.duration_minutes}} minutes</p>
        <p v-show="game.difficulty_level != null" class="d-inline-flex">Difficulty: &nbsp; &nbsp;
          <el-rate disabled v-model="game.difficulty_level"></el-rate>
        </p>
        <div class="row justify-content-center">
          <base-button class="button-wide" v-on:click="showBooking(game)" type="warning" simple >Book Now</base-button>
        </div>
      </card>
    </div>
  </div>
  <div class="ml-3 mr-3" v-if="is_selected_game">
    <card>
      <div class="row">
        <div class="col-5">
          <img slot="image" class="card-img-top fixed-size-img" :src="selectedGame.img"  alt="Card image cap"/>
        </div>
        <div class="col-7">
          <h3 class="card-title text-primary">{{selectedGame.game_name}}</h3>
          <h5>
            <i class="tim-icons text-white icon-time-alarm"></i>&nbsp; &nbsp;{{selectedGame.duration_minutes}} minutes
          </h5>
          <p class="text-white">{{selectedGame.game_description}}</p>



<!--          <p v-show="selectedGame.min_players_count != null">Players: &nbsp; &nbsp;{{selectedGame.min_players_count}}-->
<!--            <span v-show="selectedGame.max_players_count != null"> - </span> {{selectedGame.max_players_count}}</p>-->

          <p v-show="selectedGame.difficulty_level != null" class="d-inline-flex">Difficulty: &nbsp; &nbsp;
            <el-rate disabled v-model="selectedGame.difficulty_level"></el-rate>
          </p>
          <div class="row mt-5">
            <div class="col-3">
              <p class="text-white text-uppercase">Quantity <span class="text-primary font-italic">${{selectedGame.price}}</span></p>
              <base-input>
                <el-input-number v-model="number_of_players" :min="selectedGame.min_players_count" :max="selectedGame.max_players_count" @change="getSubtotal()" />
              </base-input>
            </div>
            <div class="col-4">
              <p class="text-white text-uppercase">subtotal</p>
              <h2 class="warning">${{ subtotal }}</h2>
            </div>
          </div>
        </div>
      </div>


<!--      <hr class="checkout">-->
    </card>
<!--    <div class="cal-container">-->
<!--      <div class="text-center" v-for="(w, i) in week" :key="i">-->
<!--        <h4 class="mt-3 text-uppercase">{{w.day}}</h4>-->
<!--        <h1 class="mt-2 text-success">{{w.date}}</h1>-->
<!--        <h4 class="no-upper-margin text-uppercase">{{w.month}}</h4>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row mt-1 ml-1" >
      <div class="days-width ml-1" v-for="(d, index) in week" :key="'A' + index">
        <card class="white-content text-center">

          <h4 class="mt-3 text-white text-uppercase">{{d.day}}</h4>
          <h1 class="mt-2 text-success">{{d.date}}</h1>
          <h4 class="no-upper-margin text-white text-uppercase">{{d.month}}</h4>
          <div v-for="(s, i) in d.slots" :key="'A' + i">
            <base-button class="button-wide" disabled v-if="s == null">
              <h3 class="mt-4">&nbsp;</h3>
            </base-button>
            <base-button v-if="s != null" type="success" class="button-wide">
              <h3 class="mt-4 text-white">{{ s }}</h3>
            </base-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Rate} from "element-ui";
import {BaseAlert} from 'src/components'
import {ampm} from "@/plugins/dateFormatter";

export default {
  name: "BookingPage",
  components: {
    [Rate.name]: Rate,
    BaseAlert
  },
  data() {
    return {
      subtotal: 0,
      bss: '',
      notFound: false,
      number_of_players: 0,
      loading: false,
      empty: false,
      games: '',
      is_selected_game: false,
      is_booking: false,
      selectedGame: '',
      week: [],
      day: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
      month: ["January","February","March","April","May","June","July",
        "August","September","October","November","December"]
    }
  },
  methods: {
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    showBooking(game){
      this.is_selected_game = true
      this.selectedGame = game
      this.number_of_players = this.selectedGame.min_players_count
      this.subtotal = parseFloat(this.selectedGame.price) * parseInt(this.number_of_players)
      this.associateSlots()
    },
    getSubtotal(){
      this.subtotal = parseFloat(this.selectedGame.price) * parseInt(this.number_of_players)
    },
    async getSchedules(){
      let code = this.$route.params.company
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'bookings/company-groups/' + code

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.games = response.data.schedules
              if(this.games.length <= 0 ){
                this.empty = true
              }
            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    getWeeklyDates(){
      //TODO: today for now, pass as a parameter when there is a feature to jump to other dates
      let d = new Date();
      this.week = []
      //determine the date of sunday
      let day = d.getDay()
      let diff = d.getDate() - day + (day == 0 ? -6:1);
      let monday = new Date(d.setDate(diff))
      let next = new Date(monday.valueOf())
      this.week.push({
        day: this.day[monday.getDay()],
        date: monday.getDate(),
        month: this.month[monday.getMonth()],
        slots: ['1', 2, 'abc']
      })
      for(let i = 1; i < 7; i++){
        next =  new Date(monday.valueOf())
        next = new Date(next.setDate((monday.getDate() + i)))
        this.week.push({
          day: this.day[next.getDay()],
          date: next.getDate(),
          month: this.month[next.getMonth()],
          slots: ['1', 2]
        })
      }
    },
    associateSlots(){
      let s = JSON.parse(this.selectedGame.slots)
      //assign slots
      let slots = [[], [] ,[], [], [], [], []]
      let max_count = []
      for(let n in s){
        if(s[n].day == 'Monday'){
          slots[0].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Tuesday'){
          slots[1].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Wednesday'){
          slots[2].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Thursday'){
          slots[3].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Friday'){
          slots[4].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Saturday'){
          slots[5].push(ampm(s[n].start_time))
        }
        if(s[n].day == 'Sunday'){
          slots[6].push(ampm(s[n].start_time))
        }
      }
      //find the longest array
      const lengths = slots
        .map(a => a.length)
      const index = lengths
        .indexOf(Math.max(...lengths));
      //console.log(this.selectedGame.slots)
      let diff = 0

      for(let i = 0; i < 7; i++){
        diff = lengths[index] - slots[i].length
        for(let j = 0; j < diff; j++){
          slots[i].push(null)
        }
        this.week[i].slots = slots[i]

      }
    },
    getBss(){
      var code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/booking'
      console.log(url)

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.bss = response.data.config[0]

          this.getGames(this.bss.company_group_id)
        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    },
  },
  mounted() {
    console.log('mounted')

    this.getSchedules()
    this.getWeeklyDates()
    console.log('mounted')
    this.getBss()
  }
}
</script>

<style scoped>
.button-wide{
  width: 90%;
}

.fixed-size-img {
  width: 100%; /* width of container */
  height: 300px; /* height of container */
  object-fit: cover;
}

.cal-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}
.cal-container div {
  /*background-color: blueviolet;*/
  border: 1px solid white;
  aspect-ratio: 1.75;
}
.no-upper-margin{
  margin-top: -20px;

}
.days-width{
  width: 13.8%;
}
.schedule{
  background-color: white;
  color: #000000;
}

hr.checkout {
  border-top: 1px solid white;
}
</style>
