<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

<!--  Create folder-->
  <base-button type="primary" v-on:click="modals.create_folder = true">Create new folder</base-button>
  <!--   No data panel -->
  <div v-if="folders.length <= 0" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No folders exist.</h4>
    </div>
  </div>
  <!--  Folders-->
  <div class="row mt-3">
    <div class="col-lg-2" v-for="(folder, i) in folders" :key="'A' + i">
      <base-button v-on:click="openFolder(folder)" class="fixed-size">
        {{folder.name}}
      </base-button>
    </div>
  </div>

  <modal :show.sync="modals.create_folder"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Create New Folder</h4>
      <base-input v-model="folder_name" label="Folder name"></base-input>


      <div class="row d-none d-sm-block text-center mt-3">
        <base-button type="secondary" @click="modals.create_folder = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="createFolder()">Create</base-button>
      </div>
      <div class="row text-center d-block d-sm-none mt-3">
        <base-button type="secondary" size="sm" simple @click="modals.create_folder = false">Cancel</base-button>
        <base-button class="ml-3" size="sm" simple type="primary" @click="createFolder()">Create</base-button>
      </div>
    </card>
  </modal>
</div>
</template>

<script>
import {formattedDate, ampm} from "@/plugins/dateFormatter";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import { Modal } from '@/components';

export default {
  name: "MyPhotos",
  components: {
    Modal
  },
  data() {
    return {
      loading: false,
      modals: {
        create_folder: false
      },
      folder_name: '',
      folders: []
    }
  },
  methods: {
    getDate(d) {
      return formattedDate(d)
    },
    getTime(d) {
      return ampm(d)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    openFolder(folder){
      localStorage.setItem('folder', JSON.stringify(folder))
      this.$router.push('/folders/photos')
    },
    async createFolder(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'folders'

      let data = {
        name: this.folder_name,
      }
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.create_folder = false
          this.getFolders()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async getFolders() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'folders'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.folders = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
  },
  mounted() {
    this.getFolders()
  }
}
</script>

<style scoped>
.fixed-size{
  width: 100%;
  height: 60px;
}
</style>
