<template>
  <div :style="{ 'background-color': waiverConfigurations.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px'}">
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + waiverConfigurations.font_family">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <not-found-page v-show="notFound"></not-found-page>
    <div class="row mt-2 mr-2 justify-content-end text-right ">
      <div class="col-12">
        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="!is_authenticated" link type="success" v-on:click="login()"><i class="tim-icons icon-single-02"></i> Login</base-button>
        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="!is_authenticated" link type="success" v-on:click="register()"><i class="tim-icons icon-laptop"></i> Register</base-button>
        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="is_authenticated" link type="success" v-on:click="toProfile()" class="mr-3"><i class="tim-icons icon-single-02"></i> Account</base-button>
      </div>
    </div>
    <div class="container" v-show="!notFound">
      <div class="row">
        <div class="col-md-6 mt-5 mb-4 ml-auto mr-auto text-center">
          <img style="height: 100px;" :src="waiverConfigurations.logo" alt="logo">
          <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description" v-if="currentStep === 0">
            {{waiverConfigurations.main_message}}
          </h4>
        </div>
      </div>
      <!--Step 1 - Choose your experience-->
      <div class="row justify-content-center text-center" v-if="currentStep === 0">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-puzzle-10"></i> Game
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 0">
        <div class="col-lg-3 col-md-4 col-sm-6 col-10" v-for="game in gameOptions" :key="game.id">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
            <!--<h4 class="card-title">{{game.name}}</h4>-->
            <div class="row mt-3 justify-content-center">
              <div class="col-lg-12 ">
                <base-button simple size="lg" v-on:click="selectGame(game)"
                             class="game-button"
                             :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  {{game.name}}</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

<!--      Step 2 - Pick from available booking slots-->
      <div class="row justify-content-center text-center" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-time-alarm"></i> <span class="mt-4">Time</span>
          </span></h3>
        </div>

      </div>
      <div class="row justify-content-center text-center" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col">
          <div class="row mb-1 justify-content-center" :style="{'font-family': waiverConfigurations.font_family}">
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }" v-if="showPrevious(current)" v-on:click="decrementDay(current)"  class="btn btn-link btn-primary d-none d-sm-block">
                <i class="tim-icons icon-minimal-left"></i> &nbsp; Previous
              </a>
              <base-input class="mt-2" style="border: 0; border-width:0px;">
                <el-date-picker
                  style="border: 0; border-width:0px;"
                  :size="'small'"
                  type="date"
                  :prefix-icon="' '"
                  placeholder="Select date"
                  value-format="yyyy-MM-dd"
                  format="ddd, dd MMMM yyyy"
                  v-model="current"
                  @change="changeDate()"
                  :picker-options="{ disabledDate: (time) => disabledDates(time, current) }"
                >
                </el-date-picker>
              </base-input>
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }" v-on:click="incrementDay(current)" class="btn btn-link btn-primary d-none d-sm-block">
                Next &nbsp;<i class="tim-icons icon-minimal-right"></i>
              </a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col-10" v-if="noBookingsAvailable === true">
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3">No booking times available.</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-7 col-sm-7 col-10" v-for="booking in bookings" :key="booking.id">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">

            <div class="row justify-content-center">
              <div class="col-lg-12 ">
                <base-button simple size="lg" v-on:click="selectBookingDate(booking)"
                             class="game-button"
                             :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">{{getAMPM(booking.start_time)}}&nbsp;</span>
                  </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!--      Step 3 - Select additional games-->
<!--      <div class="row justify-content-center text-center" v-if="currentStep === 2">-->
<!--        <div class="col">-->
<!--          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.button_color }">-->
<!--            <i class="tim-icons icon-puzzle-10"></i> -->
<!--          </span></h3>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row justify-content-center" v-if="currentStep === 2">
        <div class="col-lg-6 col-md-7 col-sm-7 col-10" :style="{'font-family': waiverConfigurations.font_family}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center ">
                <p class="mb-3" :style="{ 'color': waiverConfigurations.text_color}">Would you like to check in for another game?
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <base-button simple v-on:click="selectAdditionalGames()"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family }" type="success">
                  <span class="ml-1 mt-1">Yes</span>
                </base-button>
              </div>
              <div class="col-6">
                <base-button simple v-on:click="noAdditionalGames()"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family }" type="danger">
                  <span class="ml-1 mt-1">No</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>


  <!--      Step 4:  Player info-->
      <div class="row justify-content-center text-center" v-if="currentStep === 3 &&
       (display.includes('First Name') || display.includes('Last Name') || display.includes('Email')
       || display.includes('Phone'))">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-user-run"></i> <span class="mt-4">Your Info</span>
          </span></h3>
        </div>
      </div>
      <div :style="{'font-family': waiverConfigurations.font_family}" class="row justify-content-center" v-if="currentStep === 3 &&
       (display.includes('First Name') || display.includes('Last Name') || display.includes('Email')
       || display.includes('Phone'))">
        <div class="col">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row mt-5 justify-content-center">
              <div class="col-lg-5 col-sm-12 col-md-12 col-12" v-if="display.includes('First Name')">
                <label :style="{'color': waiverConfigurations.text_color}">First name
                  <span v-if="requiredFields.includes('First Name')" class="text-danger">*</span>
                </label>
                <base-input placeholder="First name"
                            v-model="user.first_name"></base-input>
                <p class="text-danger small" v-if="errors.first_name==true">First name cannot be empty</p>
              </div>
              <div class="col-lg-5 col-sm-12 col-md-12 col-12" v-if="display.includes('Last Name')">
                <label :style="{'color': waiverConfigurations.text_color}">Last name
                  <span v-if="requiredFields.includes('Last Name')" class="text-danger">*</span>
                </label>
                <base-input placeholder="Last name"
                            v-model="user.last_name"></base-input>
                <p class="text-danger small" v-if="errors.last_name==true">Last name cannot be empty</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-sm-12 col-md-12 col-12" v-if="display.includes('Birthday')">
                <label :style="{'color': waiverConfigurations.text_color}">Birthday
                  <span v-if="requiredFields.includes('Birthday')" class="text-danger">*</span>
                </label>
                <base-input
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="user.birthday"></base-input>
                <p class="text-danger small" v-if="errors.birthday==true">Birthday cannot be empty</p>
              </div>
              <div class="col-5">
               &nbsp;
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-sm-12 col-md-12 col-12" v-if="display.includes('Email') && display.includes('Phone')">
                <label :style="{'color': waiverConfigurations.text_color}">Email
                  <span v-if="requiredFields.includes('Email')" class="text-danger">*</span>
                </label>
                <base-input v-model="user.email" placeholder="Email"></base-input>
                <p class="text-danger small" v-if="errors.email==true">Please provide a valid email</p>
              </div>
              <div class="col-lg-5 col-sm-12 col-md-12 col-12" v-if="display.includes('Phone')">
                <label :style="{'color': waiverConfigurations.text_color}">Phone
                  <span v-if="requiredFields.includes('Phone')" class="text-danger">*</span>
                </label>
                <base-input
                            v-mask="'(###) ###-####'" placeholder="(999) 999-9999"
                            v-model="user.phone"></base-input>
                <p class="text-danger small" v-if="errors.phone==true">Phone number cannot be empty</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-sm-12 col-md-12 col-12" v-if="display.includes('Email') && !display.includes('Phone')">
                <label>Email
                  <span v-if="requiredFields.includes('Email')" class="text-danger">*</span>
                </label>
                <base-input v-model="user.email" placeholder="Email"></base-input>
                <p class="text-danger small" v-if="errors.email==true">Please provide a valid email</p>
              </div>
              <div class="col-2" v-if="display.includes('Email') && !display.includes('Phone')">
                &nbsp;
              </div>
            </div>
            <div class="row mb-2 justify-content-center">
              <div class="col-2 d-sm-block d-none offset-8">
                <base-button :style="{'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                  simple class="game-button" v-on:click="setUserData()">Next</base-button>
              </div>
              <div :style="{'font-family': waiverConfigurations.font_family}"  class="col d-sm-none d-block">
                <base-button class="game-button" :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                             simple v-on:click="setUserData()">Next</base-button>
              </div>
            </div>

          </card>
        </div>
      </div>

      <!--      Step 5 - Minors-->
      <div class="row justify-content-center text-center" v-if="currentStep === 4">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}" > <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-controller"></i> <span class="mt-4">Children</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 4 &&  has_minors == false">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div :style="{'font-family': waiverConfigurations.font_family}" class="col-lg-12 col-12 text-center ">
                <p class="mb-3" :style="{'color': waiverConfigurations.text_color}">Are you accompanying children?</p>
                <base-button :style="{'font-family': waiverConfigurations.font_family}" simple  v-on:click="isMinor(1)"
                             class="minor-button" type="success">
                  <span class="ml-1 mt-1">Yes</span>
                </base-button>
                <base-button :style="{'font-family': waiverConfigurations.font_family}" simple  v-on:click="isMinor(0)"
                             class="ml-2 minor-button" type="danger">
                  <span class="ml-1 mt-1">No</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 4 && has_minors == true">
        <div class="col" :style="{'font-family': waiverConfigurations.font_family}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row mt-5 justify-content-center" v-for="(m, i) in minors" :key="'A' + i">
              <div class="col-lg-3 col-sm-12 col-md-12 col-12">
                <label :style="{'color': waiverConfigurations.text_color}">First name</label>
                <base-input v-if="display.includes('Child First Name')" placeholder="First name"
                            v-model="m.first_name"></base-input>
              </div>
              <div class="col-lg-3 col-sm-12 col-md-12 col-12">
                <label :style="{'color': waiverConfigurations.text_color}">Last name</label>
                <base-input v-if="display.includes('Child Last Name')" placeholder="Last name"
                            v-model="m.last_name"></base-input>
              </div>
              <div class="col-lg-3 col-sm-12 col-md-12 col-12">
                <label :style="{'color': waiverConfigurations.text_color}">Birthday</label>
                <base-input v-if="display.includes('Child Birthday')"
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="m.birthday"></base-input>
              </div>
              <div class="mt-4 col-lg-1 col-sm-12 col-md-12 col-12">
                <base-button icon type="success" v-on:click="addMinor()" round>+</base-button>
              </div>
            </div>
            <div class="row mb-2 justify-content-center">
              <div class="mt-3 col-2 d-sm-block d-none offset-8">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                             class="game-button" simple type="success" v-on:click="setMinorData()">Next</base-button>
              </div>
              <div class="mt-3 col d-sm-none d-block">
                <base-button :style="{'font-family': waiverConfigurations.font_family,  'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                             class="game-button" simple type="success" v-on:click="setMinorData()">Next</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>


      <!--      Step 6 - Signature and waiver text-->
      <div class="row justify-content-center text-center" v-if="currentStep === 5">
        <div class="col" :style="{'font-family': waiverConfigurations.font_family}">
          <h3> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-notes"></i> <span class="mt-4">Sign waiver</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 5">
        <div class="col-lg-9 col-md-10 col-sm-12 col-12" :style="{'font-family': waiverConfigurations.font_family}">
          <card
                footer-classes="text-center mb-3 mt-3"
          >
            <textarea :style="{'background-color': 'white'}" v-if="waiverConfigurations.waiver_text" disabled rows="30" v-model="waiverConfigurations.waiver_text"></textarea>
            <div class="ml-3 mt-2" v-if="waiverConfigurations.external_link">
              <base-checkbox v-model="conditions">
               <span :style="{'color': waiverConfigurations.text_color}">I have reviewed and agree to <a target="_blank" :href="waiverConfigurations.external_link">terms and conditions</a>.</span>

              </base-checkbox>
              <p class="text-danger small" v-if="errors.conditions==true">You must accept terms and conditions</p>
            </div>
            <div v-if="display.includes('Signature')" class="row mt-3 justify-content-between">
              <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                <h6 :style="{'color': waiverConfigurations.text_color}" class="mt-3">Signature</h6>
              </div>
              <div class="mr-3 ml-3">
                <base-button :style="{'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color,'color': waiverConfigurations.button_color}" size="sm" align="left" simple v-on:click="clearSignature()" type="secondary">Clear Signature</base-button>
              </div>
            </div>

            <div class="row" v-if="display.includes('Signature')">

              <div class=" col-lg-12">
                <VueSignaturePad :custom-style="background_sig" :style="{'border-color': waiverConfigurations.button_color}" class="sig-pad" height="200px" ref="signaturePad" />
                <p class="text-danger small" v-if="errors.signature==true">Signature cannot be empty</p>
              </div>
            </div>
            <div v-if="display.includes('Photo Opt Out')" class="row">
              <div class="col-12 col-lg-12">
                <base-checkbox class="ml-1" v-model="picture_optout">
                  <span :style="{'color': waiverConfigurations.text_color}">Do not share my photos on social media</span>
                </base-checkbox>
              </div>
            </div>
            <div v-if="display.includes('Email Opt Out')" class="row">
              <div class="col-12 col-lg-12">
                <base-checkbox class="ml-1" v-model="email_optout">
                  <span :style="{'color': waiverConfigurations.text_color}">Do not include me in mailing lists</span>
                </base-checkbox>
              </div>
            </div>
            <div class="row mt-3 mb-2 justify-content-center">
              <div class="col-2 d-sm-block d-none offset-8">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                         simple  class="mr-3 game-button" v-on:click="setSignatureData()">Next</base-button>
              </div>
              <div class="col d-sm-none d-block">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                             simple  class="mr-3 game-button" v-on:click="setSignatureData()">Next</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

    <!--      Step 7 - Confirm-->
      <div class="row justify-content-center text-center" v-if="currentStep === 6">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <i class="tim-icons icon-satisfied"></i> <span class="mt-4">Almost done!</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 6">
        <div class="col-lg-6 col-md-7 col-sm-7 col-10" :style="{'font-family': waiverConfigurations.font_family}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center">
                <p :style="{'color': waiverConfigurations.text_color}" class="mb-3">Please confirm your check-in for</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center " v-for="(c, i) in checkInGames" :key="'C' + i">
                  <p :style="{'color': waiverConfigurations.text_color}" class="mb-3"><span class="text-success">{{c.game.name}}</span>
                    on {{getFormattedDate(c.booking.booking_date)}}
                    at {{getAMPM(c.booking.start_time)}}
                  </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center">
                <base-button simple  v-on:click="confirmCheckin()"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">Confirm</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {API_LOCATION, WAIVER_URL} from 'src/api-config'
import { BaseAlert } from 'src/components';
import {Select, Option, DatePicker, TimePicker, TimeSelect} from 'element-ui'
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
const axios = require('axios')
import {date, time, ampm, formattedDate} from '/src/plugins/dateFormatter'
import {Auth} from "aws-amplify";
import {AmplifyEventBus} from "aws-amplify-vue";
import swal from "sweetalert2";
import _ from 'lodash'

const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

export default {
  name: "Waiver",
  components: {
    NotFoundPage,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [TimePicker.name]: TimePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseAlert,
  },
  data() {
    return {
      background_sig: { 'background-color': 'white'},
      loading: false,
      has_minors: false,
      bookings: '',
      noBookingsAvailable: false,
      picture_optout: false,
      email_optout: false,
      selectedBooking: '',
      selectedGame: '',
      checkInGames: [],
      notFound: '',
      currentStep: 0,
      gameOptions: '',
      is_authenticated: false,
      waiverConfigurations: '',
      conditions: false,
      current: '',
      fields: [],
      display: [],
      signature: '',
      user: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        birthday: ''
      },
      minors: [{
        first_name: '',
        last_name: '',
        birthday: ''
      }],
      errors: {
        email: false,
        first_name: false,
        last_name: false,
        birthday: false,
        phone: false,
        signature: false,
        conditions: false
      },
      requiredFields: [],
    }
  },
  computed: {
    today(){
      return new Date()
    }
  },
  methods: {
    selectAdditionalGames(){
      //don't add the same booking slot
      let match = _.filter(this.checkInGames, _.matches({booking: this.selectedBooking}))
      if(match.length <= 0){
        this.checkInGames.push({
          game: this.selectedGame,
          booking: this.selectedBooking
        })
      }
      this.currentStep = 0
    },
    noAdditionalGames(){
      this.checkInGames.push({
        game: this.selectedGame,
        booking: this.selectedBooking
      })
      this.currentStep = 3
    },
    disabledDates(time) {
      var date = new Date();
      var previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    getAMPM(d){
      return ampm(d)
    },
    getFormattedDate(d){
      return formattedDate(d)
    },
    setToday(){
      let current = new Date()
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
    },
    showPrevious(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      return current > this.today
    },
    changeDate(){
      this.getBookingsForGame(this.selectedGame.id)
    },
    decrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() - 1)
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      this.getBookingsForGame(this.selectedGame.id)
    },
    incrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() + 1)
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      this.getBookingsForGame(this.selectedGame.id)
    },
    getUserInfo() {
      return new Promise ( function(resolve) {
        Auth.currentUserInfo()
          .then(data =>{
            resolve(data)
          })
          .catch(err => console.log(err))
      })
    },
    isMinor(is_minor){
      if(is_minor == 0){
        if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
          this.currentStep = 5
          return
        }
        this.currentStep = 6
      } else {
        this.has_minors = true
      }
    },
    setMinorData(){
      if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
        this.currentStep = 5
        return
      }
      this.currentStep = 6
    },
    addMinor(){
      this.minors.push(
        {
          fist_name: '',
          last_name: '',
          birthday: ''
        }
      )
    },
    getSignature () {
      if(this.$refs.signaturePad == undefined){
        return
      }
      var png =  this.$refs.signaturePad.saveSignature().data
      if (this.$refs.signaturePad.isEmpty()) {
        return ''
      } else {
        return png
      }
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature()
    },
    setSignatureData(){
      this.signature = this.getSignature()
      let errors = 0

      if(this.signature == ''){
        errors++
        this.errors.signature = true
      } else {
        this.errors.signature = false
      }
      if(this.waiverConfigurations.external_link != null && this.conditions == false){
        errors++
        this.errors.conditions = true
      } else {
        this.errors.conditions = false
      }
      if(errors > 0){
        return
      }

      this.currentStep = 6
    },
    async confirmCheckin(){
      let b = this.user.birthday
      if(this.user.birthday != undefined || this.user.birthday != ''){
        b =  b.split('/')[2] + '-' + b.split('/')[0] + '-' + b.split('/')[1]
      }

      for(let n in this.minors){
        if(this.minors[n].first_name == '' && this.minors[n].last_name == '' && this.minors[n].birthday == ''){
          this.minors.splice(n, 1)
        }
      }
      for(let m in this.minors){
        if(this.minors[m].birthday != undefined || this.minors[m].birthday != ''){
          this.minors[m].birthday =  this.minors[m].birthday.split('/')[2] + '-' + this.minors[m].birthday.split('/')[0] + '-' + this.minors[m].birthday.split('/')[1]
        }
      }



      this.loading = true
      let url = API_LOCATION + 'checkin'
      this.user.birthday = b
      let data = {
        company_id: this.waiverConfigurations.company_id,
        user: this.user,
        bookings: this.checkInGames,
        minors: this.minors,
        signature: this.signature,
        picture_optout: this.picture_optout,
        email_optout: this.email_optout
      }


      this.axios.post(url, data)
        .then(response => {
          this.loading = false
          swal({
            title: 'Congratulations!',
            text: `You are all set`,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#fd5d93',
            confirmButtonText: 'Ok'
          }).then((response)=> {
            this.$router.go()
          })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
          if(err.response.data == 'Waiver record already exists'){
            swal({
              title: 'Ooops!',
              text: `Looks like you already checked in for this experience`,
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Ok'
            }).then((response)=> {
              this.$router.go()
            })
          }
        })
    },
    getDate(d){
      return date(d)
    },
    getTime(d){
      return time(d)
    },
    getGames (id) {
      let company_id = this.waiverConfigurations.company_id
      let url = WAIVER_URL + 'companies/' + company_id + '/groups/' + id + '/games'
      axios.get(url)
        .then(response => {
          this.gameOptions = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    getWaiverConfigurations(){
      this.loading = true
      var code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/waiver'

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.waiverConfigurations = response.data.config[0]
          this.fields = response.data.fields
          for(let n in this.fields){
            if(this.fields[n].is_active == 1){
              this.display.push(this.fields[n].name)
              if(this.fields[n].is_required){
                this.requiredFields.push(this.fields[n].name)
              }
            }
          }
          this.loading = false
          this.getGames(this.waiverConfigurations.company_group_id)
        })
        .catch(err => {
          this.loading = false
          this.notFound = true
          console.log(err)
        })
    },
    selectGame(game){
      this.currentStep = 1
      this.selectedGame = game
      this.getBookingsForGame(this.selectedGame.id)

    },
    async getBookingsForGame(gameId){
      this.loading = true
      let url = API_LOCATION + 'games/' + gameId + '/bookings?from=' + this.current + `&to=` + this.current
      axios.get(url)
        .then(response => {
          this.loading = false
          this.bookings = response.data.booking_slots
          if(this.bookings.length <= 0){
            this.noBookingsAvailable = true
          } else {
            this.noBookingsAvailable = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    login(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/login')
    },
    register(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/register')
    },
    toProfile(){
      this.$router.push('/settings/profile')
    },
    async isAuthenticated(){
      let token = await this.getAuthToken()
      if(token == 'No current user'){
        this.is_authenticated = false
      } else {
        this.is_authenticated = true
        this.user = JSON.parse(localStorage.getItem('user'))
      }
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    setUserData(){
      let errors = 0
      if(this.isEmailValid(this.user.email) == false){
        errors++
        this.errors.email = true
      } else {
        this.errors.email = false
      }
      if(this.user.first_name == '' && this.requiredFields.includes('First Name')){
        errors++
        this.errors.first_name = true
      } else {
        this.errors.first_name = false
      }
      if(this.user.last_name == '' && this.requiredFields.includes('Last Name')){
        errors++
        this.errors.last_name = true
      } else {
        this.errors.last_name = false
      }
      if(this.user.birthday == '' && this.requiredFields.includes('Birthday')){
        errors++
        this.errors.birthday = true
      } else {
        this.errors.birthday = false
      }
      if(this.user.phone == '' && this.requiredFields.includes('Phone')){
        errors++
        this.errors.phone = true
      } else {
        this.errors.phone = false
      }
      if(errors > 0){
        return
      }
      if(this.display.includes('Child First Name') || this.display.includes('Child Last Name') || this.display.includes('Child Birthday')){
        this.currentStep = 4
        return
      } else if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
        this.currentStep = 5
        return
      }
      this.currentStep = 6
    },
    async selectBookingDate(booking){
      this.selectedBooking = booking
      if(this.waiverConfigurations.multiple_games == 1){
        this.currentStep = 2
      } else {
        this.checkInGames.push({
          game: this.selectedGame,
          booking: this.selectedBooking
        })
        this.currentStep = 3
      }

    },
    async getUser(){
      let user = await this.getUserInfo()
      let redirect = '/checkin/' + this.$route.params.company
      if(user == null){
        localStorage.setItem('redirect', redirect)
      }
    }

  },
  mounted() {
    this.setToday()
    this.getUser()
    this.getWaiverConfigurations()
    this.isAuthenticated()
  }
}
</script>

<style>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 150px; /* height of container */
  object-fit: cover;
}
.game-button{
  width: 100%;
}
.minor-button{
  width: 40%;
}
.sig-pad {
  border: #344675 2px solid;
  border-radius: 5px;
}
textarea
{
  width:100%;
  margin:5px 0;
  padding:1%;
}
</style>
