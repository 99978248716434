<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
<!--  Games-->
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-10" v-for="game in games" :key="game.id">
      <card >
        <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
        <h4 class="card-title">
          <span class="text-success">{{game.game_name}}</span> at {{game.company_name}}</h4>
        <p class="text-white">{{getDate(game.booking_date)}},
          {{getAMPM(game.booking_time)}}
        </p>
        <p class="text-white" v-if="game.completed == 1">
          <i class="text-warning tim-icons icon-trophy"></i> {{getCompletionTime(game.start_time, game.end_time)}}
        </p>
        <div class="row justify-content-end">
          <base-button v-on:click="viewDetails(game)" class="mr-3" type="warning" simple size="sm">View Details</base-button>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {formattedDate, time, ampm} from 'src/plugins/dateFormatter'

export default {
  name: "PastGames",
  data() {
    return {
      loading: false,
      empty: false,
      games: ''
    }
  },
  methods: {
    getAMPM(d){
      return ampm(d)
    },
    getDate(d){
      return formattedDate(d)
    },
    getTime(d){
      return time(d)
    },
    getCompletionTime(start, end){
      let end_time = new Date(end)
      let start_time = new Date(start)
      let diffTime = (end_time - start_time)
      let total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
      return total_minutes + ' min'
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getPastGames(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.games = response.data
              if(this.games.length <= 0 ){
                this.empty = true
              }
            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    viewDetails(game){
      localStorage.setItem('gameInstanceId', JSON.stringify(game.id))
      this.$router.push('/game-details')
      // this.loading = true
      // let token = await this.getAuthToken()
      // let config = {headers: {Authorization: token}}
      // let url = API_LOCATION + 'games/' + game.id
      //
      // axios.get(url, config)
      //   .then(response => {
      //     let gameDetails = {
      //       puzzles: response.data.puzzles,
      //       number_of_players: response.data.number_of_players,
      //       game: game,
      //       uploaded_image: response.data.uploaded_image
      //     }
      //     localStorage.setItem('gameDetails', JSON.stringify(gameDetails))
      //     this.$router.push('/game-details')
      //     this.loading = false
      //
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //     if(err.response.status == 401){
      //       this.$router.push('/forbidden')
      //     }
      //   })
    }
  },
  mounted() {
    this.getPastGames()
  }
}
</script>

<style scoped>

</style>
