<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col-md-8">
        <card>
          <h4 slot="header" class="card-title">My profile</h4>

          <div class="card-description">
            <form @submit.prevent="updateProfile">
              <div class="row">
                <div class="col-md-5">
                  <div class="row text-center">
                    <div class="col-lg-12">

                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        type="text"
                        label="First Name"
                        placeholder="First Name"
                        v-model="user.first_name"
                      >
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input
                        type="text"
                        label="Last Name"
                        placeholder="Last Name"
                        v-model="user.last_name"
                      >
                      </base-input>
                    </div>
                  </div>
                  <base-input
                    type="email"
                    label="Email address"
                    placeholder="Email"
                    :disabled="true"
                    v-model="user.email"
                  >
                  </base-input>
                  <base-input
                    type="text"
                    label="Phone"
                    placeholder="(999) 999-9999"
                    v-mask="'(###) ###-####'"
                    v-model="user.phone"
                  >
                  </base-input>
                  <base-input
                    type="text"
                    label="Birthday"
                    placeholder="mm-dd-yyyy"
                    v-model="user.birthday"
                  >
                  </base-input>

                </div>
              </div>

              <base-button @click.prevent native-type="submit" type="primary" v-on:click="updateProfile()">
                Save
              </base-button>
            </form>
          </div>
        </card>
      </div>


      <div class="col-md-4">
        <card class="card-user">
          <p class="card-text"></p>
          <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <img class="avatar" :src="img" alt="..." />
            <p class="description">
              <span class="btn btn-info btn-sm btn-simple btn-file">Upload New Image
                <input
                  accept="image/*"
                  @change="handlePreview"
                  type="file"
                  name="profile"
                  class="valid"
                  :multiple="false"
                  aria-invalid="false"
                />
              </span>
              <span v-show="newImage" class="btn btn-primary btn-sm btn-simple btn-file">Save
                <input
                  v-on:click="saveFile"
                />
              </span>
            </p>
          </div>
        </card>


      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <card>
          <h4 slot="header" class="card-title">Change password</h4>
          <base-alert v-if="error.length > 0" type="danger">
            <i class="fas fa-exclamation-triangle fa-2x"></i>
            {{error}}
          </base-alert>
          <base-alert v-if="message.length > 0" type="success">
            <i class="fas fa-exclamation-triangle fa-2x"></i>
            {{message}}
          </base-alert>
          <base-input
            type="password"
            label="Old Password"
            v-model="old_password"
          >
          </base-input>
          <base-input
            type="password"
            label="New Password"
            v-model="new_password"
          >
          </base-input>
          <base-button v-on:click="changePassword()" type="primary">
            Change Password
          </base-button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import { ImageUpload, BaseAlert, Modal } from 'src/components';
  import { Loading } from 'element-ui';
  import { API_LOCATION } from '../../api-config'
  import swal from 'sweetalert2'
  const axios = require('axios')
  import { Auth } from 'aws-amplify'
  import {date, reverseDate} from '../../plugins/dateFormatter'
  import { BTable } from 'bootstrap-vue'

  export default {
      name: "MyProfile",
      components: {
        ImageUpload,
        Loading,
        BaseAlert,
        BTable,
        Modal
      },
      data() {
        return {
          fields: ['name', 'actions'],
          modals: {
            convert_minor: false
          },
          user: {
            phone: '',
            first_name: '',
            last_name: '',
            birthday: '',
            email: '',
            img: ''
          },
          file: '',
          loading: false,
          old_password: '',
          new_password: '',
          error: '',
          message: '',
          img: '',
          newImage: false,
          companies: '',
          empty: false,
          emptyMinors: false,
          minors: [],
          selectedMinor: ''
        }
      },
      methods: {
        convertMinorModal(item) {
          this.modals.convert_minor = true
          this.selectedMinor = item
        },
        getAuthToken() {
          return new Promise(function (resolve) {
            Auth.currentSession()
              .then(data => {
                resolve(data.idToken.jwtToken)
              })
              .catch(err => console.log(err))
          })
        },
        getDate(d) {
          return date(d)
        },
        reverseDate(d) {
          return reverseDate(d)
        },
        async changePassword() {
          this.loading = true
          let user = await Auth.currentAuthenticatedUser()
          try {
            await Auth.changePassword(user, this.old_password, this.new_password)
            this.message = 'Password successfully changed!'
            this.error = ''
            this.new_password = ''
            this.old_password = ''
            this.loading = false
          } catch (err) {

            if (err.code === 'InvalidParameterException' || err.code === 'InvalidPasswordException') {
              this.error = 'Password must be at least 6 characters long and must have uppercase characters.'
              this.message = ''
            } else if (err.code === 'NotAuthorizedException') {
              this.error = 'Old password is not correct.'
              this.message = ''
            } else if (err.code === 'LimitExceededException') {
              this.error = 'Too many requests. Please try again later.'
              this.message = ''
            }
            this.new_password = ''
            this.old_password = ''
            this.loading = false
          }
        },
        async updateProfile() {
          this.loading = true
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'profile'
          let data = this.user
          data.birthday = this.reverseDate(data.birthday)


          axios.put(url, data, config)
            .then(response => {
              this.loading = false
              //refresh the page
              axios.get(url, config)
                .then(response => {
                  let user = response.data[0]
                  user.birthday = this.getDate(user.birthday)
                  this.user = user
                  this.img = user.img
                  localStorage.setItem('user', JSON.stringify(user))
                })

            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if (err.response.status == 401) {
                this.$router.push('/forbidden')
              }
            })
        },
        handlePreview(event) {
          let file = event.target.files[0];
          console.log(file)
          this.img = URL.createObjectURL(file);
          console.log(this.img)
          this.file = file
          this.newImage = true
          this.$emit('change', file);
        },
        async saveFile() {
          this.loading = true
          if (this.file != '') {
            this.uploadUrl = await this.getSignedURL(this.file)
            let uploaded = await this.uploadFile(this.file)
            //Get the image URL
            let imgUrl = this.uploadUrl.split("?")
            this.user.img = imgUrl[0]
            localStorage.setItem('user', JSON.stringify(this.user))
            //update the db
            let token = await this.getAuthToken()
            let config = {headers: {Authorization: token}}
            let url = API_LOCATION + 'profile/picture'
            let data = {img: this.user.img}
            axios.put(url, data, config)
              .then(response => {
                this.loading = false
              })
              .catch(err => {
                this.loading = false
                console.log(err)
                if (err.response.status == 401) {
                  this.$router.push('/forbidden')
                }
              })
          }
        },
        uploadFile(file) {
          let url = this.uploadUrl
          let config = {
            headers: {
              'Content-Type': file.type
            }
          }

          let payload = file
          return new Promise(function (resolve) {
            axios.put(url, payload, config)
              .then((res) => {
                resolve(res)
              })
              .catch((err) => {
                console.error(err)
              })
          })
        },
        async getSignedURL(file) {
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let url = API_LOCATION + 'upload'

          let payload = {
            filePath: file.name,
            contentType: file.type
          }

          return new Promise(function (resolve) {
            axios.post(url, payload, config)
              .then((res) => {
                resolve(res.data)
              })
              .catch((err) => {
                console.error(err)
              })
          })
        },

      },
      mounted(){
        this.user = JSON.parse(localStorage.getItem('user'))
        this.img = this.user.img
      }

    }
</script>

<style scoped>
  #loading {
    min-height: 1000px;
    display: flex;
    align-items: center;
  }

  .el-loading-spinner .path {
    stroke: #6aff8d !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }

</style>
