<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <h2>{{folder_name}}
<!--    <el-tooltip content="Delete folder"-->
<!--                effect="light"-->
<!--                :open-delay="300"-->
<!--                placement="top">-->
<!--      <base-button-->
<!--        @click.native="editFolderName()"-->
<!--        class="like btn-link"-->
<!--        type="success"-->
<!--        size="sm"-->
<!--        icon>-->
<!--        <i class="tim-icons icon-pencil"></i>-->
<!--      </base-button>-->
<!--    </el-tooltip>-->
    <el-tooltip content="Delete folder"
                effect="light"
                :open-delay="300"
                placement="top">
      <base-button
                   @click.native="deleteFolder()"
                   class="like btn-link"
                   type="danger"
                   size="sm"
                   icon>
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </el-tooltip>

  </h2>

  <div class="row">
    <div class="col">
      <span class="btn btn-primary btn-file">Upload photo
                <input type="file"
                       accept="image/*"
                       @change="takePhoto()"

                />
      </span>
    </div>
  </div>

  <card class="mt-3" v-if="photos.length > 0">
    <h4>Photos</h4>
    <div>
      <div  v-for="(p, i) in photos" :key="'A' + i">
        <p class="ml-2">{{getFormattedDate(p.timestamp)}}, {{getTime(p.timestamp)}}
          <el-tooltip content="Delete"
                      effect="light"
                      :open-delay="300"
                      placement="top">
            <base-button
              @click.native="deletePhoto(p)"
              class="like btn-link"
              type="danger"
              size="sm"
              icon>
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </el-tooltip>
        </p>
        <b-img class="ml-2 fixed-size-img" style="object-fit: cover" thumbnail left :src="p.file_name"></b-img>

      </div>
    </div>

    <!--    <vueper-slides  :bullets="false" fixed-height="500px">-->
<!--      <vueper-slide-->
<!--        v-for="(p, i) in photos"-->
<!--        :key="'A' + i"-->
<!--        :image="p.file_name">-->
<!--      </vueper-slide>-->
<!--    </vueper-slides>-->
  </card>



</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { BImg } from 'bootstrap-vue'
import {ampm, formattedDate} from "@/plugins/dateFormatter";



export default {
  name: "FolderPhotos",
  components: {
    VueperSlides,
    VueperSlide,
    BImg
  },
  data() {
    return {
      loading: false,
      modals: {
        create_folder: false
      },
      folder_name: '',
      photos: [],
      file: '',
      uploaded_image: ''
    }
  },
  methods: {
    getFormattedDate(d){
      return formattedDate(d)
    },
    getTime(dt){
      let time = dt.split('T')[1]
      return ampm(time)
    },
    handleUpload(){
      let file = event.target.files[0];
      this.uploaded_image = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    async takePhoto(){
      let t = new Date().toLocaleString( 'sv' );
      t = t.split(' ')[0]
      this.handleUpload()
      this.loading = true
      await this.saveFile()
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'folders/' + JSON.parse(localStorage.getItem('folder')).id

      let data = {
        uploaded_image:  this.uploadUrl.split("?")[0],
        timestamp: new Date()
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.file = ''
          this.uploadUrl = ''
          console.log('getting')
          this.getPhotos()

          //this.$router.go()
        }).catch((err) => {
        console.log(err)
        this.loading = false
        if(err.response.status == 401){
          this.$router.push('/forbidden')
        } else {
          swal({
            title: 'Something went wrong!',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Try again',
            buttonsStyling: false
          })
        }
      })
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    deletePhoto(photo){
      swal({
        title: 'Are you sure?',
        text: `The photo will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.deletePhotoConfirm(photo);
        }
      })
    },
    deleteFolder(){
      swal({
        title: 'Are you sure?',
        text: `The folder and all the photos in it will be permanently deleted`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Yes, delete it!'
      }).then((response)=> {
        if(response.value == true){
          this.delete();
        }
      })
    },
    async deletePhotoConfirm(p){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'media/' + p.id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.getPhotos()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
    async delete(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'folders/' + JSON.parse(localStorage.getItem('folder')).id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.$router.push('/folders')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
    async getPhotos() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'folders/' + JSON.parse(localStorage.getItem('folder')).id

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.photos = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
  },
  mounted() {
    this.folder_name = JSON.parse(localStorage.getItem('folder')).name
    this.getPhotos()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 300px; /* width of container */
  height: 200px; /* height of container */
  object-fit: cover;
}
</style>
