/**
 * Functions to format date and time
 */


/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns MM-DD-YYYY
 */
function getDate(d){
  let dd = d.split('T')[0]
  let date = dd.split('-')
  return date[1] + '-' + date[2] + '-' + date[0]
}

function getFormattedDate(d){
  if(d == undefined){
    return
  }
  let months = ["January","February","March","April","May","June","July",
    "August","September","October","November","December"]
  let date = ''
  if(d.length > 10){
    let dd = d.split('T')[0]
    date = dd.split('-')
  } else {
    date = d.split('-')
  }
  return date[2] + ' ' + months[parseInt(date[1]-1)] + ' ' + date[0]
}


/**
 * Takes MM-DD-YYYY and returns YYYY-MM-DD
 */
function setDate(d) {
  console.log('before reversal')
  console.log(d)
  let date = d.split('-')
  return date[2] + '-' + date[0] + '-' + date[1]
}

/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns HH-MM AM/PM
 */
function getTime(d){
  if(d == undefined){
    return
  }
  let date = d.split('T')[1]
  let time = date.split(':')

  let hour = parseInt(time[0])
  let minute = time[1]

  let dd = 'AM'
  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
  }
  if (hour == 0) {
    hour = 12;
  }
  if (hour < 10) {
    hour = '0' + hour
  }

  return hour + ':' + minute + ' ' + dd
}

function getAmPm(d){
  if(d == undefined){
    return
  }
  let hour = d.split(':')[0]
  let minute = d.split(':')[1]
  let dd = 'AM'
  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
    if (hour < 10) {
      hour = '0' + hour
    }
  }
  if (hour == 0) {
    hour = 12;
  }

  return hour + ':' + minute + ' ' + dd
}

export {getDate as date, getAmPm as ampm, getTime as time, setDate as reverseDate, getFormattedDate as formattedDate}
