<template>
  <div :style="{ 'background-color': bss.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px'}">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <not-found-page v-show="notFound"></not-found-page>
    <div class="row justify-content-end text-right mt-3">
      <div class="col-12">
        <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" v-if="!is_authenticated" simple v-on:click="login()" class="mr-3">Login</base-button>
        <base-button  :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" v-if="is_authenticated" simple v-on:click="toProfile()" class="mr-3">Account</base-button>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard ref="wiz">
          <template slot="header">
            <img style="height: 70px;" :src="bss.logo" alt="logo">
            <h3 class="card-title mt-3">{{ bss.title }}</h3>
            <p>{{bss.subtext}}</p>
          </template>
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-components"></i>
              <p>Experience</p>
            </template>
            <div class="row ml-5 mr-5">
              <div class="col-lg-4 col-md-4 col-sm-6 col-10" v-for="game in games" :key="game.id">
                <card>
                  <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
                  <p class="address-overlay"><i class="tim-icons icon-square-pin"></i> <span class="small"> {{game.address}}, {{game.city}}, {{game.state}} {{game.zip}}</span></p>
                  <h4 :style="{ 'color': bss.button_color }" class="card-title ">{{game.game_name}}</h4>
                  <p v-show="game.min_players_count != null">Players: &nbsp; &nbsp;{{game.min_players_count}}
                    <span v-show="game.max_players_count != null"> - </span> {{game.max_players_count}}</p>
                  <p v-show="game.duration_minutes != null">Duration: &nbsp; &nbsp;{{game.duration_minutes}} minutes</p>
                  <p class="d-inline-flex">Difficulty: &nbsp; &nbsp;
                    <el-rate disabled v-model="game.difficulty_level"></el-rate>
                  </p>
                  <div class="row justify-content-center">
                    <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }"
                      class="button-wide" v-on:click="tabOne(1, game)" simple >Book Now</base-button>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i v-on:click="getPricing()" class="tim-icons icon-simple-add"></i>
              <p>Quantity</p>
            </template>
            <card>
              <div class="row">
                <div class="col-5">
                  <img slot="image" class="card-img-top fixed-size-img" :src="selectedGame.img"  alt="Card image cap"/>
                </div>
                <div class="col-7">
                  <h3 :style="{'color': bss.button_color }" class="card-title">{{selectedGame.game_name}}</h3>
                  <h5>
                    <i class="tim-icons text-white icon-time-alarm"></i>&nbsp; &nbsp;{{selectedGame.duration_minutes}} minutes
                  </h5>
                  <p class="text-white">{{selectedGame.game_description}}</p>
                  <p class="mt-3" v-show="selectedGame.min_players_count != null">Players: &nbsp; &nbsp;{{selectedGame.min_players_count}}
                    <span v-show="selectedGame.max_players_count != null"> - </span> {{selectedGame.max_players_count}}
                  </p>
                  <p class="d-inline-flex">Difficulty: &nbsp; &nbsp;
                    <el-rate disabled v-model="selectedGame.difficulty_level"></el-rate>
                  </p>
                  <div class="row mt-5">
                    <div class="col-3">
                      <p class="text-white text-uppercase">Quantity <span class="text-primary font-italic">${{getAmount(ppp)}}</span></p>
                      <base-input>
                        <el-input-number v-model="number_of_players" :min="selectedGame.min_players_count" :max="selectedGame.max_players_count" @change="getSubtotal()" />
                      </base-input>
                    </div>
                    <div class="col-4">
                      <p class="text-white text-uppercase">subtotal</p>
                      <h2 class="warning">${{ subtotal }}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" class="button-wide" v-on:click="tabTwo(2)" simple >Continue</base-button>
              </div>
            </card>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i v-on:click="getBookingSlots()" class="tim-icons icon-calendar-60"></i>
              <p>Date/time</p>
            </template>
            <div class="row mb-1 justify-content-end text-right mr-1">
              <div class="col">
                <a v-if="incrementCount > 0" v-on:click="decrementWeek()"  class="btn btn-link btn-primary">
                  <i class="tim-icons icon-minimal-left"></i> &nbsp;Previous
                </a>
                <a v-if="incrementCount > 0" v-on:click="setToday()" class="btn btn-link btn-primary">
                  Today &nbsp;
                </a>
                <a v-on:click="incrementWeek()" class="btn btn-link btn-primary">
                  Next &nbsp;<i class="tim-icons icon-minimal-right"></i>
                </a>
              </div>
            </div>
            <div class="row mt-1 ml-1" >
              <div class="days-width ml-1" v-for="(d, index) in week" :key="'A' + index">
                <card class="white-content text-center">
                  <h4 class="mt-3 text-white text-uppercase">{{d.day}}</h4>
                  <h1 class="mt-2 text-success">{{d.date}}</h1>
                  <h4 class="no-upper-margin text-white text-uppercase">{{d.month}}</h4>
                  <div v-for="(s, i) in d.slots" :key="'A' + i">
                    <base-button class="button-slot" disabled v-if="s == null">
                      <h3 class="mt-4">&nbsp;<br>
                        <span class="subtext">&nbsp;</span>
                      </h3>
                    </base-button>
                    <base-button disabled v-if="s != null && (s.status == 'expired')"
                                 v-on:click="tabThree(s,3)"
                                 type="default" class="button-slot">
                      <h3 class="mt-4 text-white">{{ getAMPM(s.start_time) }}<br>
                        <span class="subtext">expired</span>
                      </h3>
                      <span class="mt-4 text-white"></span>
                    </base-button>
                    <base-button v-if="s != null && (s.status == 'available' || s.status == 'checked')"
                                 v-on:click="tabThree(s,3)"
                                 type="success" class="button-slot">
                      <h3 class="mt-4 text-white">{{ getAMPM(s.start_time) }}<br>
                        <span class="subtext">book now</span>
                      </h3>
                      <span class="mt-4 text-white"></span>
                    </base-button>
                    <base-button v-if="s != null && (s.status == 'booked' || s.status == 'blocked')" type="danger"
                                 disabled class="button-slot">
                      <h3 class="mt-4 text-white">{{ getAMPM(s.start_time) }}
                        <br>
                        <span class="subtext">sold out</span>
                      </h3>
                    </base-button>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-single-02"></i>
              <p>Account</p>
            </template>
            <div class="row ml-5">
              <div class="col-8">
                <card>
                  <div class="row ml-5">
                    <div class="col-12">
                      <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" size="sm" v-if="!is_authenticated" simple v-on:click="login()" class="mr-3">Login to your account</base-button>
                    </div>
                  </div>
                  <div class="row ml-5 mt-4">
                    <div class="col-11">
                      <h3 :style="{ 'color': bss.button_color }" class="card-title">Personal information</h3>
                    </div>
                  </div>
                  <div class="row ml-5">
                    <div class="col-5">
                      <base-input v-model="user.first_name" label="First name"></base-input>
                      <p class="text-danger small" v-if="errors.first_name==true">First name cannot be empty</p>
                    </div>
                    <div class="col-5">
                      <base-input v-model="user.last_name" label="Last name"></base-input>
                      <p class="text-danger small" v-if="errors.last_name==true">Last name cannot be empty</p>
                    </div>
                  </div>
                  <div class="row ml-5">
                    <div class="col-5">
                      <base-input v-model="user.email" type="email" label="Email"></base-input>
                      <p class="text-danger small" v-if="errors.email==true">Please provide a valid email</p>
                    </div>
                    <div class="col-5">
                      <base-input placeholder="(999)-999-9999" v-mask="'(###)-###-####'" v-model="user.phone" label="Phone"></base-input>
                      <p class="text-danger small" v-if="errors.phone==true">Please provide a phone number</p>
                    </div>
                  </div>
                  <div class="row ml-5">
                    <div class="col-11">
                      <base-checkbox v-model="terms">I agree to terms and conditions</base-checkbox>
                      <p class="text-danger small ml-4" v-if="errors.terms==true">Please accept our terms and conditions</p>
                    </div>
                  </div>
                  <div class="row mt-2 justify-content-center">
                    <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" class="button-wide" v-on:click="tabFour(4)" simple >Continue</base-button>
                  </div>
                </card>
              </div>
              <div class="col-3">
                <card>
                  <div class="row justify-content-center">
                    <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }"  v-on:click="tabZero(0)" class="button-wide no-upper-margin" simple size="sm">Add another booking</base-button>
                  </div>
                  <div class="row mt-3">
                    <div class="col-11">
                      <h4 class="card-title">Your bookings &nbsp;
                      <i class="tim-icons icon-cart"></i>
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="row" v-for="(c, index) in cart" :key="'A' + index">
                        <div class="col-3">
                          <img class="game-img" :src="c.game.img"/>
                        </div>
                        <div class="col-9">
                          <p class="text-success" style="font-size: medium">{{c.game.game_name}}
                            <base-button
                              @click="removeBooking(index)"
                              class="like btn-link"
                              type="danger"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </p>
                          <p class="text-white"><span class="font-weight-bold">Date:</span> {{getFormattedDate(c.booking.booking_date)}}</p>
                          <p class="text-white"><span class="font-weight-bold">Time:</span> {{getAMPM(c.booking.start_time)}}</p>
                          <p class="text-white"><span class="font-weight-bold">Quantity:</span> {{c.quantity}}</p>
                          <p class="text-white"><span class="font-weight-bold">Subtotal:</span> ${{c.subtotal}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style="background-color: white">
                  <div class="row ml-5">
                    <h4 class="text-white">Total: ${{getTotalAmount()}}</h4>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
              <i class="tim-icons icon-cart"></i>
              <p>Billing</p>
            </template>
            <div class="row ml-5">
              <div class="col-8">
                <card>
                  <div class="row ml-5 mt-3">
                    <div class="col-11">
                      <h3 :style="{ 'color': bss.button_color }" class="card-title">Billing information</h3>
                    </div>
                  </div>
                  <div class="row ml-5">
                    <div class="col-6">
                      <base-input v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
                      <p class="text-danger small" v-if="errors.ccn==true">Please provide a valid credit card number</p>
                    </div>
                    <div class="col-3">
                      <base-input v-mask="'#####'" placeholder="11111" v-model="payment.zip" type="text" label="Zip code"></base-input>
                      <p class="text-danger small" v-if="errors.zip==true">Please provide a valid zip code</p>
                    </div>
                  </div>
                  <div class="row ml-5">
                    <div class="col-3">
                      <base-input v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
                      <p class="text-danger small" v-if="errors.expiration==true">Please provide a valid expiration date</p>
                    </div>
                    <div class="col-3">
                      <base-input v-mask="'###'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
                      <p class="text-danger small" v-if="errors.cvv==true">Please provide a valid CVV code</p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" class="button-wide" v-on:click="tabFive(5)" type="primary" simple >Complete booking</base-button>
                  </div>
                </card>
              </div>
              <div class="col-3">
                <card>
                  <div class="row justify-content-center">
                    <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" v-on:click="tabZero(0)" type="success" class="button-wide no-upper-margin" simple size="sm">Add another booking</base-button>
                  </div>
                  <div class="row mt-3">
                    <div class="col-11">
                      <h4 class="card-title">Your bookings &nbsp;
                        <i class="tim-icons icon-cart"></i>
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="row" v-for="(c, index) in cart" :key="'A' + index">
                        <div class="col-3">
                          <img class="game-img" :src="c.game.img"/>
                        </div>
                        <div class="col-9">
                          <p class="text-success" style="font-size: medium">{{c.game.game_name}}
                            <base-button
                              @click="removeBooking(index)"
                              class="like btn-link"
                              type="danger"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </p>
                          <p class="text-white"><span class="font-weight-bold">Date:</span> {{getFormattedDate(c.booking.booking_date)}}</p>
                          <p class="text-white"><span class="font-weight-bold">Time:</span> {{getAMPM(c.booking.start_time)}}</p>
                          <p class="text-white"><span class="font-weight-bold">Quantity:</span> {{c.quantity}}</p>
                          <p class="text-white"><span class="font-weight-bold">Subtotal:</span> ${{c.subtotal}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style="background-color: white">
                  <div class="row ml-5">
                    <h4 class="text-white">Total: ${{getTotalAmount()}}</h4>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => wizardComplete()">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Review</p>
            </template>
            <card>
              <div class="row mt-3 justify-content-center">
                <h3 class="card-title">Thank you for booking with {{selectedGame.company}}!</h3>
              </div>
              <div class="row justify-content-center">
                <div class="col-6">
                  <hr class="white-content">
                  <h4>Booking details</h4>

                  <div class="row mt-3 mr-2 justify-content-end">
                    <p>Order reference number: #{{order_number}}</p>
                  </div>
                  <div class="row mr-2 justify-content-end">
                    <p>Payment status: <span class="text-success">PAID</span> </p>
                  </div>
                  <div class="row mt-3 justify-content-center" v-for="(c, index) in cart" :key="'A' + index">
                    <div class="col-6">
                      <p style="font-size: medium"><span class="text-success" >{{c.game.game_name}}</span> at {{getAMPM(c.booking.start_time)}}, {{getFormattedDate(c.booking.booking_date)}}
                      </p>
                    </div>
                    <div class="col-6">
                      <p style="font-size: medium">Quantity: {{c.quantity}}
                      </p>
                    </div>
                  </div>
                  <div class="row mt-3 mr-2 justify-content-end">
                    <h4>Amount paid: ${{getTotalAmount()}}</h4>
                  </div>
                  <div class="row mt-3 justify-content-center">
                    <div class="col-12">
                      <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" v-on:click="goHome()" class="ml-3" simple>Back</base-button>
                      <base-button :style="{ 'border-color': bss.button_color, 'color': bss.button_color }" v-on:click="goToCheckIn()" class="ml-3" simple>Check-in</base-button>

                    </div>
                  </div>
                </div>
              </div>

            </card>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { SimpleWizard, WizardTab } from 'src/components'
import SelectGame from "@/pages/Bookings/SelectGame";
import swal from 'sweetalert2'
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Rate} from "element-ui";
import {ampm, formattedDate, date, time} from "@/plugins/dateFormatter";
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";

export default {
  name: "CreateBooking",
  components: {
    SimpleWizard,
    WizardTab,
    SelectGame,
    [Rate.name]: Rate,
    NotFoundPage
  },
  data() {
    return {
      bss: '',
      notFound: false,
      monday: '',
      sunday: '',
      terms: false,
      subtotal: 0,
      number_of_players: 0,
      loading: false,
      empty: false,
      games: '',
      pricing: '',
      ppp: '',
      booking_slots: '',
      incrementCount: 0,
      selectedGame: {
        difficulty_level: 0
      },
      selectedBooking: '',
      cart: [],
      week: [],
      is_authenticated: false,
      day: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
      month: ["January","February","March","April","May","June","July",
        "August","September","October","November","December"],
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      payment: {
        amount: '',
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      order_number: '',
      timestamp: '',
      errors: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        terms: false,
        ccn: false,
        zip: false,
        expiration: false,
        cvv: false
      }
    }
  },
  methods: {
    goToCheckIn(){
      let code = this.$route.params.company
      let path = '/checkin/' + code
      this.$router.push(path)

    },
    goHome(){
      this.$router.push('/')

    },
    getDate(d){
      return date(d)
    },
    getTime(d){
      return time(d)
    },
    toProfile(){
      this.$router.push('/settings/profile')
    },
    login(){
      let redirect = '/book/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/login')
    },
    async isAuthenticated(){
      let token = await this.getAuthToken()
      if(token == 'No current user'){
        this.is_authenticated = false
      } else {
        this.is_authenticated = true
        this.user = JSON.parse(localStorage.getItem('user'))
      }
    },
    getAMPM(d){
      return ampm(d)
    },
    getFormattedDate(d){
      return formattedDate(d)

    },
    removeBooking(index){
      this.cart.splice(index, 1)
    },
    getTotalAmount(){
      let total = 0
      for(let n in this.cart){
        total += parseFloat(this.cart[n].subtotal)
      }
      this.payment.amount = total
      return total.toFixed(2)
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    tabZero(index){
      this.$refs['wiz'].tabIndex(index);
    },
    tabOne(index, game){
      this.$refs['wiz'].tabIndex(index);
      this.selectedGame = game
      this.number_of_players = this.selectedGame.min_players_count
      this.getPricing()
    },
    tabTwo(index){
      this.$refs['wiz'].tabIndex(index)
      this.setToday()
      this.getBookingSlots()
    },
    tabThree(booking, index){
      this.selectedBooking = booking
      let found = 0
      for(let n in this.cart){
        if(booking.id == this.cart[n].booking.id){
          found++
        }
      }
      if(found <= 0){
        this.cart.push({
          game: this.selectedGame,
          booking: this.selectedBooking,
          quantity: this.number_of_players,
          subtotal: this.subtotal

        })
      }
      this.$refs['wiz'].tabIndex(index)
    },
    tabFour(index){
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let errors = 0
      if(this.user.first_name == ''){
        this.errors.first_name = true
        errors++
      } else {
        this.errors.first_name = false
      }
      if(this.user.last_name == ''){
        this.errors.last_name = true
        errors++
      } else {
        this.errors.last_name = false
      }
      if(this.user.email == '' || (emailRegex.test(this.user.email) == false)){
        this.errors.email = true
        errors++
      } else {
        this.errors.email = false
      }
      if(this.user.phone == ''){
        this.errors.phone = true
        errors++
      } else {
        this.errors.phone = false
      }
      if(this.terms == ''){
        this.errors.terms = true
        errors++
      } else {
        this.errors.terms = false
      }
      if(errors > 0){
        return
      }
      this.$refs['wiz'].tabIndex(index)
    },
    tabFive(){
      let errors = 0
      if(this.payment.cardNumber == '' || this.payment.cardNumber.length < 19){
        this.errors.ccn = true
        errors++
      } else {
        this.errors.ccn = false
      }
      if(this.payment.zip == '' || this.payment.zip < 5){
        this.errors.zip = true
        errors++
      } else {
        this.errors.zip = false
      }
      if(this.payment.expirationDate == ''){
        this.errors.expiration = true
        errors++
      } else {
        this.errors.expiration = false
      }
      if(this.payment.cardCode == '' || this.payment.cardCode < 3 ){
        this.errors.cvv = true
        errors++
      } else {
        this.errors.cvv = false
      }
      if(errors > 0){
        return
      }
      this.completeBooking()
    },
    disableTabsTest(){
      this.$refs['wiz'].disableTabs();
    },
    async completeBooking(){
      let code = this.$route.params.company
      this.loading = true
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/payment'
      this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
      let year = '20' + this.payment.expirationDate.split('/')[1]
      let month = this.payment.expirationDate.split('/')[0]
      this.payment.expirationDate = year + '-' + month
      let data = {
        bookings: this.cart,
        payment: this.payment,
        user: this.user
      }
      axios.post(url, data)
        .then(response => {
          this.loading = false
          this.order_number = response.data.orderId
          this.timestamp = response.data.timestamp
          swal({
            title: 'Congratulations!',
            text: `Your booking is confirmed`,
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((response)=> {
            this.$refs['wiz'].disableTabs();
            this.$refs['wiz'].tabIndex(5)
          })
        })
        .catch(err => {
          this.loading = false
          if(err.response.data == 'This booking is no longer available'){
            swal({
              title: 'Sorry!',
              text: `This booking is no longer available`,
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Start over'
            }).then((response)=> {
              this.tabZero(0)
            })

          } else if (err.response.status == 500){
            swal({
              title: 'Invalid transaction!',
              text: `Please check the information you provided`,
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Back to booking'
            }).then((response)=> {
              this.tabZero(0)
            })
          }
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async getBookingSlots(){
      this.loading = true
      let url = API_LOCATION + 'games/' + this.selectedGame.game_id + '/bookings?from=' + this.monday + '&to=' + this.sunday

      axios.get(url)
        .then(response => {
          this.loading = false
          this.booking_slots = response.data.booking_slots
          this.associateSlots()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    async getPricing(){
      this.loading = true
      let url = API_LOCATION + 'games/' + this.selectedGame.game_id + '/pricing'

      axios.get(url)
        .then(response => {
          this.loading = false
          this.pricing = response.data.pricing
          for(let n in this.pricing){
            if(this.pricing[n].min_players == this.number_of_players){
              this.ppp = this.pricing[n].price
              this.subtotal = (parseFloat(this.number_of_players) * parseFloat(this.ppp)).toFixed(2)
              return
            }
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      // this.$refs['step3'].loadModel(this.wizardModel)
    },
    async wizardComplete() {
      // var created = await this.$refs['step3'].createGame()
      // if(created.status == 200){
      //   swal('Good job!', 'You created a new game!', 'success')
      //     .then((response)=> {
      //       localStorage.removeItem('game')
      //       this.$router.push('setup')
      //     })
      // } else {
      //   swal('Something went wrong', 'Please review the form!', 'error');
      //
      // }
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => {
              console.log(err)
          })
      })
    },
    async getSchedules(){
      let code = this.$route.params.company
      this.loading = true
      let url = API_LOCATION + 'bookings/company-groups/' + code

      axios.get(url)
        .then(response => {
          this.loading = false
          this.games = response.data.schedules
          if(this.games.length <= 0 ){
            this.empty = true
          }

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    getAmount(integer){
      if(integer == null){
        return
      }
      return parseFloat(integer).toFixed(2);
    },
    getSubtotal(){
      for(let n in this.pricing){
        if(this.pricing[n].min_players == this.number_of_players){
          this.ppp = this.pricing[n].price
          this.subtotal = (parseFloat(this.number_of_players) * parseFloat(this.ppp)).toFixed(2)
          return
        }
      }
    },
    decrementWeek(){
      this.incrementCount--
      this.getWeeklyDates()
    },
    setToday(){
      this.incrementCount = 0
      this.getWeeklyDates()
    },
    incrementWeek(){
      this.incrementCount++
      this.getWeeklyDates()
    },
    getWeeklyDates(){
      let d = new Date();
      //increment
      d.setDate(d.getDate() + (7 * parseInt(this.incrementCount)))
      this.week = []
      //determine the date of sunday
      let day = d.getDay()
      let diff = d.getDate() - day + (day == 0 ? -6:1);
      let monday = new Date(d.setDate(diff))
      let next = new Date(monday.valueOf())
      this.week.push({
        day: this.day[monday.getDay()],
        date: monday.getDate(),
        month: this.month[monday.getMonth()],
        slots: []
      })
      for(let i = 1; i < 7; i++){
        next =  new Date(monday.valueOf())
        next = new Date(next.setDate((monday.getDate() + i)))
        this.week.push({
          day: this.day[next.getDay()],
          date: next.getDate(),
          month: this.month[next.getMonth()],
          slots: []
        })
      }

      this.monday = monday.getFullYear() + '-'  + (monday.getMonth()+1) + '-' + monday.getDate()
      this.sunday = next.getFullYear() + '-'  + (next.getMonth()+1) + '-' + next.getDate()
      this.getBookingSlots()
    },
    associateSlots(){
      let slots = [[], [] ,[], [], [], [], []]
      let date = 0

      for(let n in this.booking_slots){
        date = this.booking_slots[n].booking_date.split('T')[0].split('-')[2]
        for(let m in this.week){
          if(parseInt(date) == parseInt(this.week[m].date)){
            slots[m].push(this.booking_slots[n])
          }
        }
      }
      //find the longest array
      const lengths = slots
        .map(a => a.length)
      const index = lengths
        .indexOf(Math.max(...lengths));
      let diff = 0
      for(let i = 0; i < 7; i++){
        diff = lengths[index] - slots[i].length
        for(let j = 0; j < diff; j++){
          slots[i].push(null)
        }
        this.week[i].slots = slots[i]
      }
    },
    getBss(){
      var code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/booking'

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.bss = response.data.config[0]
        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    }
  },
  mounted() {
    this.getBss()
    this.getSchedules()
    this.getWeeklyDates()
    this.isAuthenticated()

  }
}
</script>

<style scoped>
.button-wide{
  width: 90%;
}
.no-upper-margin{
  margin-top: -15px;
}
.button-slot{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 90%;
}
.days-width{
  width: 13.8%;
}
.fixed-size-img {
  width: 100%; /* width of container */
  height: 250px; /* height of container */
  object-fit: cover;
}
.subtext{
  font-size: small;
}

.game-img{
  display: inline-flex;
  align-items: flex-end;
  justify-content: right;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.address-overlay{
  margin-top: -70px;
  margin-bottom: 30px;
}
</style>
