<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
  <!--  Purchases-->
  <h3 v-if="purchases.length > 0">Purchases</h3>
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-10" v-for="p in purchases" :key="p.id">
      <card >
        <img slot="image" class="card-img-top fixed-size-img" :src="p.img"  alt="Card image cap"/>
        <h4 class="card-title">
          <span class="text-success">{{p.game_name}}</span> at {{p.company_name}}
        </h4>

        <p class="text-white"><i class="text-success tim-icons icon-calendar-60"></i>
         &nbsp;{{getDate(p.booking_date)}}
          &nbsp;<i class="text-success tim-icons icon-time-alarm"></i>
          &nbsp;{{getAMPM(p.start_time)}}
          &nbsp;
        </p>
        <p class="text-white"><i class="text-success tim-icons icon-square-pin"></i>
          &nbsp;{{p.address}}, {{p.city}} {{p.state}}, {{p.zip}}
        </p>
        <div class="row justify-content-end mr-1">
          <base-button class="ml-1" simple type="success"  size="sm" disabled>
            <!--             <i class="tim-icons icon-check-2"></i> -->
            PAID</base-button>
        </div>
        <p class="text-white">
          Check-in link: <a :href="getLink(p.code)">{{getLink(p.code)}}</a>

        </p>
      </card>
    </div>
  </div>
  <!--  Checkins-->
  <h3 v-if="check_ins.length > 0">Completed check-ins</h3>
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-10" v-for="c in check_ins" :key="c.id">
      <card >
        <img slot="image" class="card-img-top fixed-size-img" :src="c.img"  alt="Card image cap"/>
        <h4 class="card-title">
          <span class="text-success">{{c.game_name}}</span> at {{c.company_name}}</h4>
        <p class="text-white"><i class="text-success tim-icons icon-calendar-60"></i>
          &nbsp;{{getDate(c.booking_date)}}
          &nbsp;<i class="text-success tim-icons icon-time-alarm"></i>
          &nbsp;{{getAMPM(c.start_time)}}
        </p>
        <p class="text-white"><i class="text-success tim-icons icon-square-pin"></i>
          &nbsp;{{c.address}}, {{c.city}} {{c.state}}, {{c.zip}}
        </p>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {formattedDate, time, ampm} from "@/plugins/dateFormatter";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";

export default {
  name: "FutureGames",
  data() {
    return {
      loading: false,
      empty: false,
      purchases: [],
      check_ins: [],
    }
  },
  methods: {
    getDate(d) {
      return formattedDate(d)
    },
    getTime(d) {
      return time(d)
    },
    getAMPM(d) {
      return ampm(d);
    },
    getLink(code){
      return 'https://offthecouch.io/checkin/' + code
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getFutureGames() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games/upcoming'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.purchases = response.data.transactions
              this.check_ins = response.data.check_ins
              if (this.purchases.length <= 0 && this.check_ins.length <= 0) {
                this.empty = true
              }

            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
  },
  mounted() {
    this.getFutureGames()
  }
}
</script>

<style scoped>

</style>
